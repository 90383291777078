<template>
  <b-card>
    <FormView
      :data="$store.getters['multipleGame/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'gamesData',
          label: 'Games',
          type: 'avatars',
          titleKey: 'name',
          imageKey: 'image',
        },
        {
          key: 'sort',
          label: 'Sort',
          prefix: '#',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('multipleGame/getDetail', id)
    },
  },
}
</script>

<style scoped>

</style>
